<template>
  <v-card class="mx-auto" outlined>
    <v-card-title>
      <v-row align-self="start" class="ml-5">
        <v-col cols="9">
          Parámetros de búsqueda <v-icon></v-icon>
          <v-select
            cache-items
            chips
            multiple
            deletable-chips
            v-model="reporte_seleccionado"
            :items="reporte_seleccionado_items"
            label="Reportes"
            return-object
            ><template v-slot:item="{ index, item }">
              <span class="black--text"
                ><v-icon>{{ item.icon }}</v-icon> {{ item.text }}</span
              >
            </template></v-select
          >
        </v-col>
        <v-col cols="2">
          <h6>Total de registros: {{ consolidado_excel_detalle.length }}</h6>
        </v-col>

        <v-col>
          <v-btn
            class="float-right mb-2"
            color="teal"
            outlined
            icon
            :title="show ? 'Ocultar parámetros' : 'Mostrar parámetros'"
            @click="show = !show"
          >
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

          <v-btn
            @click="fn_consolidado_excel()"
            class="float-right"
            color="green"
            outlined
            icon
            :title="'Descargar detalle de #check-ins'"
          >
            <v-icon> mdi mdi-microsoft-excel </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="11">
          <p>Del {{ selectedDias[0] }} al {{ selectedDias[1] }}</p>
          <div class="text-caption" v-if="flag_formulario_con_parametros">
            <p>
              <v-icon>mdi mdi-magnify </v-icon>
              {{ reporte_seleccionado.text }} Parámetros aplicados:
            </p>
            <!-- prettier-ignore -->
            <p v-if="searchSupervisora.length > 0">Usuario(s): <span
              v-html="
                fn_arrayToCommaStringUsuarios(searchSupervisora,'nombre_completo', 'alias')
              "
            ></span>
            </p>
            <p v-if="searchDivision.length > 0">
              Divisione(s):
              <span
                v-html="
                  fn_arrayToCommaString(searchDivision, 'division', 'division')
                "
              ></span>
            </p>
            <p v-if="searchRegion.length > 0">
              Regione(s):
              <span
                v-html="fn_arrayToCommaString(searchRegion, 'region', 'region')"
              ></span>
            </p>
            <p v-if="searchSector.length > 0">
              Sector(s):
              <span
                v-html="fn_arrayToCommaString(searchSector, 'codigo', 'codigo')"
              ></span>
            </p>
            <p v-if="searchAgenteDeComercio.length > 0">
              Tipos de agente(s) de comercio:
              <span
                v-html="
                  fn_arrayToCommaString(searchAgenteDeComercio, null, null)
                "
              ></span>
            </p>
            <p v-if="searchConsejera.length > 0">
              Consejera(s):
              <!-- prettier-ignore -->
              <span
                v-html="
                  fn_arrayToCommaString(searchConsejera, 'nombre_completo', 'nombre_completo')
                "
              ></span>
            </p>
            <!-- prettier-ignore -->
            <p v-if="search_motivo_de_visita_id != null &&search_motivo_de_visita_id.length >= 1">
              Motivo(s) de visita #check-ins:
              <!-- prettier-ignore -->
              <span
                v-html="
                  fn_arrayToCommaString(search_motivo_de_visita_id, 'label', 'label')
                "
              ></span>
            </p>
            <!-- prettier-ignore -->
            <p v-if="searchComentario != null && searchComentario != ''"
              :title="reporte_seleccionado.text != 'Reuniones' ? 'Buscar por alguna palabra contenida en los comentarios' : 'Buscar por alguna palabra contenida en la descripción de las reuniones'">
              <span v-text="reporte_seleccionado.text != 'Reuniones' ? 'Comentario' : 'Descripción'"></span>:
              <span>{{searchComentario}}</span>
            </p>
            <!-- prettier-ignore -->
            <p v-if="searchProspecto != null && searchProspecto != ''" 
            title="Buscar por nombre, dirección o teléfono de prospecto">
              Prospecto:
              <!-- prettier-ignore -->
              <span
              >{{searchProspecto}}</span>
            </p>
            <p
              v-if="searchNombramiento != null && searchNombramiento != ''"
              title="Buscar por nombre, dirección, teléfono o número de precalificación de nombramiento"
            >
              Nombramiento:
              <!-- prettier-ignore -->
              <span
              >{{searchNombramiento}}</span>
            </p>
          </div>
          <div class="text-caption" v-else></div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-subtitle>
      <!-- TODO: colocar los parametros vigentes -->
    </v-card-subtitle>
    <v-expand-transition>
      <v-card-text v-show="show">
        <v-row>
          <!-- FECHAS -->
          <v-col cols="6">
            <!-- FECHA -->
            <!-- SEARCH || mess -->
            <v-dialog
              ref="mesDialog"
              v-model="modalMes"
              :return-value.sync="dialogVarSelectedMes"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedMes"
                  label="Mes:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="dialogVarSelectedMes"
                scrollable
                type="month"
                show-adjacent-months
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalMes = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.mesDialog.save(dialogVarSelectedMes),
                      fn_dialogVarSelectedMes(dialogVarSelectedMes)
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- SEARCH || mess -->
          </v-col>
          <v-col cols="6">
            <!-- /*** Dashboard select - dias */ -->
            <v-dialog
              ref="diaDialog"
              v-model="modalFechas"
              :return-value.sync="dialogVarSelectedDia"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDias"
                  label="Dias:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                locale="es"
                v-model="dialogVarSelectedDia"
                :range="true"
                scrollable
                :min="dialogVarMinDia"
                :max="dialogVarMaxDia"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.diaDialog.save(dialogVarSelectedDia),
                      fn_dialogVarSelectedDia(dialogVarSelectedDia)
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- /*** Dashboard select - dias */ -->
            <!-- FECHA -->
          </v-col>
          <!-- FECHAS -->
          <!-- DIVISIONES -->
          <v-col cols="4">
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Buscar divisiones'"
              clearable
              v-model="searchDivision"
              :items="itemsDivisiones"
              title="Mostrar solo #check-ins que pertenezcan a las divisiones seleccionadas"
              :loading="loadingDivisiones"
              :search-input.sync="searchSyncDivisiones"
              color="primary"
              hide-selected
              item-text="division"
              item-value="division"
              label="Divisiones"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- DIVISIONES -->
          <!-- REGIONES -->
          <v-col cols="4">
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Buscar regiones'"
              clearable
              v-model="searchRegion"
              :items="itemsRegiones"
              title="Mostrar solo #check-ins que pertenezcan a las regiones seleccionadas"
              :loading="loadingRegiones"
              :search-input.sync="searchSyncRegiones"
              color="primary"
              hide-selected
              item-text="region"
              item-value="region"
              label="Regiones"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- REGIONES -->
          <!-- SECTORES -->
          <v-col cols="4">
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Buscar sectores'"
              clearable
              v-model="searchSector"
              :items="itemsSectores"
              title="Mostrar solo #check-ins que pertenezcan a los sectores seleccionados"
              :loading="loadingSectores"
              :search-input.sync="searchSyncSectores"
              color="primary"
              hide-selected
              item-text="codigo"
              item-value="codigo"
              label="Sectores"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- SECTORES -->
          <!-- AGENTE DE COMERCIO -->
          <v-col cols="12">
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchAgenteDeComercio"
              :items="$store.state.globalAgentesDeComercio"
              title="Mostrar solo #check-ins realizados por usuarios que sean del tipo de agente de comercio seleccionado"
              :loading="loadingAgenteDeComercio"
              color="primary"
              label="Tipo de agente de comercio"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- AGENTE DE COMERCIO -->
          <!-- SUPERVISORA -->
          <v-col cols="12">
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Buscar usuarios'"
              clearable
              v-model="searchSupervisora"
              :items="itemsSupervisoras"
              :loading="loadingSupervisoras"
              :search-input.sync="searchSyncSupervisoras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              title="Mostrar solo #check-ins realizados por los usuarios seleccionados"
              label="Usuario(S)"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- SUPERVISORA -->

          <!-- CONSEJERAS -->
          <v-col
            cols="12"
            v-if="
              flag_mostrar_parametro_if_motivo_de_visita_presente('Visitas')
            "
          >
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchConsejera"
              :items="itemsConsejeras"
              :loading="loadingConsejeras"
              :search-input.sync="searchSyncConsejeras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              title="Mostrar solo #check-ins a consejeras seleccionadas"
              label="Consejera"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- CONSEJERAS -->
          <!-- MOTIVOS -->
          <v-col
            cols="6"
            v-if="
              flag_mostrar_parametro_if_motivo_de_visita_presente('Visitas')
            "
          >
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="search_motivo_de_visita_id"
              :items="localStoreMotivos"
              color="primary"
              item-text="label"
              item-value="id"
              title="Mostrar solo #check-ins por motivo seleccionado"
              label="Motivo(s) de visita #check-ins"
              placeholder="buscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <!-- MOTIVOS -->
          <!-- COMENTARIOS -->
          <v-col cols="6">
            <!-- prettier-ignore -->
            <v-text-field
             
             v-if="reporte_seleccionado.text != 'Nombramientos'"
              clearable
              v-model="searchComentario"
              :title="texto_para_comentario_titulo"
              :label="texto_para_comentario_label"
              prepend-icon="mdi-chat"
            ></v-text-field>
          </v-col>
          <!-- COMENTARIOS -->
          <!-- PROSPECTO -->
          <v-col
            cols="6"
            v-if="
              flag_mostrar_parametro_if_motivo_de_visita_presente('Prospectos')
            "
          >
            <v-text-field
              clearable
              v-model="searchProspecto"
              title="Búsca por nombre, dirección o teléfono de prospecto"
              label="Prospecto"
              prepend-icon="mdi-account"
            ></v-text-field>
          </v-col>
          <!-- PROSPECTO -->
          <!-- NOMBRAMIENTO -->
          <v-col
            cols="6"
            v-if="
              flag_mostrar_parametro_if_motivo_de_visita_presente(
                'Nombramientos'
              )
            "
          >
            <v-text-field
              clearable
              v-model="searchNombramiento"
              title="Búsca por nombre, dirección, teléfono o número de precalificación de nombramiento"
              label="Nombramiento"
              prepend-icon="mdi-account"
            ></v-text-field>
          </v-col>
          <!-- NOMBRAMIENTO -->
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('EmitAplicarFiltros', true)"
            small
            elevation=""
            color="primary"
            dark
            >Aplicar parametros</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';

require('moment/locale/es.js');
export default {
  name: 'diarioParametrosDeBusqueda',
  props: [
    'consolidado_excel_detalle',
    'consolidado_excel_resumen_usuarios',
    'consolidado_excel_resumen_divisiones',
    'consolidado_excel_resumen_regiones',
    'consolidado_excel_resumen_sectores',
    'consolidado_excel_resumen_motivos',
  ],
  data() {
    return {
      reporte_seleccionado: [
        { text: 'Visitas', icon: 'mdi-map-marker' },
        { text: 'Inicios de labores', icon: 'mdi-account-clock' },
        { text: 'Prospectos', icon: 'mdi-account-multiple-plus' },
        { text: 'Nombramientos', icon: 'mdi-card-account-details-outline' },
        { text: 'Reuniones', icon: 'mdi-account-group-outline' },
        { text: 'Actividades', icon: 'mdi-map-marker' },
      ],
      reporte_seleccionado_items: [
        { text: 'Visitas', icon: 'mdi-map-marker' },
        { text: 'Inicios de labores', icon: 'mdi-account-clock' },
        { text: 'Prospectos', icon: 'mdi-account-multiple-plus' },
        { text: 'Nombramientos', icon: 'mdi-card-account-details-outline' },
        { text: 'Reuniones', icon: 'mdi-account-group-outline' },
        { text: 'Actividades', icon: 'mdi-map-marker' },
      ],
      text_array: 'Usuario 1, usuario 2, usuario 3',
      //
      modal: false,
      //parametros
      show: false,

      // prospecto
      searchProspecto: '',
      // nombramiento y precalificacion
      searchNombramiento: '',
      //motivos de visita (params view)
      search_motivo_de_visita_id: null,

      // variables generales para todos los formularios
      searchComentario: '',
      //supervisoras auntocomplete vars
      itemsSupervisoras: [],
      loadingSupervisoras: false,
      searchSyncSupervisoras: '',
      //agentes de comercio auntocomplete vars
      //itemsAgentesDeComercio: ,
      loadingAgenteDeComercio: false,
      searchAgenteDeComercio: '',
      //consejeras auntocomplete vars
      itemsConsejeras: [],
      loadingConsejeras: false,
      searchSyncConsejeras: '',
      //sectores auntocomplete vars
      itemsSectores: [],
      loadingSectores: false,
      searchSyncSectores: '',
      //divisiones auntocomplete vars
      itemsDivisiones: [],
      loadingDivisiones: false,
      searchSyncDivisiones: '',
      //sectores auntocomplete vars
      itemsRegiones: [],
      loadingRegiones: false,
      searchSyncRegiones: '',
      //search variables
      searchSupervisora: '',
      searchConsejera: '',
      searchSector: '',
      searchDivision: '',
      searchRegion: '',
      //search fecha
      dialogVarSelectedMes: null,
      dialogVarSelectedDia: null,
      modalMes: false,
      modalFechas: false,
      selectedDias: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ],
    };
  },

  watch: {
    selectedDias(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('EmitSelectedDate', newValue);
      }
    },
    search_motivo_de_visita_id(newValue) {
      this.$emit('EmitMotivoDeVisitaId', newValue);
    },
    searchSupervisora(newValue) {
      this.$emit('EmitSearchSupervisora', newValue);
    },
    searchAgenteDeComercio(newValue) {
      this.$emit('EmitsearchAgenteDeComercio', newValue);
    },
    searchConsejera(newValue) {
      this.$emit('EmitSearchConsejera', newValue);
    },
    searchSector(newValue) {
      this.$emit('EmitSearchSector', newValue);
    },
    searchDivision(newValue) {
      this.$emit('EmitSearchDivision', newValue);
    },
    searchRegion(newValue) {
      this.$emit('EmitSearchRegion', newValue);
    },
    searchSyncSupervisoras(newValue) {
      if (newValue && newValue != '') {
        let currentSupervisora = '';
        if (this.searchSupervisora != null) {
          currentSupervisora = this.searchSupervisora.nombre_completo;
        }

        if (newValue != currentSupervisora) {
          this.loadingSupervisoras = true;
          //levanta la funcion: fnGetAutocompleteSupervisoras
          this.fnGetAutocompleteSupervisoras();
        }
      }
    },
    searchSyncConsejeras(newValue) {
      if (newValue && newValue != '') {
        if (this.searchConsejera == null) {
          this.loadingConsejeras = true;
          //levanta la funcion: fnGetAutocompleteConsejeras
          this.fnGetAutocompleteConsejeras();
        } else {
          let currentConsejera = this.searchConsejera.nombre_completo;

          if (newValue != currentConsejera) {
            this.loadingConsejeras = true;
            //levanta la funcion: fnGetAutocompleteConsejeras
            this.fnGetAutocompleteConsejeras();
          }
        }
      }
    },
    searchSyncSectores(newValue) {
      if (newValue && newValue != '') {
        this.loadingSectores = true;
        //levanta la funcion: fnGetAutocompleteSectores
        this.fnGetAutocompleteSectores();
      }
    },
    searchSyncDivisiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingDivisiones = true;
        //levanta la funcion: fnGetAutocompleteDivisiones
        this.fnGetAutocompleteDivisiones();
      }
    },
    searchSyncRegiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingRegiones = true;
        //levanta la funcion: fnGetAutocompleteRegiones
        this.fnGetAutocompleteRegiones();
      }
    },
    searchComentario(newValue) {
      this.$emit('EmitSearchComentario', newValue);
    },
    searchProspecto(newValue) {
      this.$emit('EmitSearchProspecto', newValue);
    },
    searchNombramiento(newValue) {
      this.$emit('EmitSearchNombramiento', newValue);
    },

    /** reporte seleccionado */
    reporte_seleccionado(newValue) {
      this.$emit('EmitReporteSeleccionado', newValue);
      // reset opciones que no se encuentra entre los reportes parametrizados
      const indexPara_visitas = newValue.findIndex(
        (item) => item.text === 'Visitas'
      );

      if (indexPara_visitas == -1) {
        this.search_motivo_de_visita_id = null;
        this.searchConsejera = '';
      }

      const indexPara_prospecto = newValue.findIndex(
        (item) => item.text === 'Prospectos'
      );

      if (indexPara_prospecto == -1) {
        this.searchProspecto = '';
      }

      const indexPara_nombramiento = newValue.findIndex(
        (item) => item.text === 'Nombramientos'
      );

      if (indexPara_nombramiento == -1) {
        this.searchNombramiento = '';
      }
    },
  },
  methods: {
    fn_dialogVarSelectedMes(newValue) {
      if (newValue) {
        this.selectedDias = [
          moment(newValue).startOf('month').format('DD-MM-YYYY'),
          moment(newValue).endOf('month').format('DD-MM-YYYY'),
        ];
        this.dialogVarSelectedDia = [
          moment(newValue).startOf('month').format('YYYY-MM-DD'),
          moment(newValue).endOf('month').format('YYYY-MM-DD'),
        ];
      }
    },
    fn_dialogVarSelectedDia(newValue) {
      this.selectedDias = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
    //
    /**
     * bandera utilizada para mostrar parametros, si es que se encuentra entre reportes seleccionados
     */
    flag_mostrar_parametro_if_motivo_de_visita_presente(motivo) {
      const index = this.reporte_seleccionado.findIndex(
        (item) => item.text === motivo
      );

      if (index > -1) return true;
      else {
        return false;
      }
    },
    //
    fn_consolidado_excel() {
      let xlsx = require('json-as-xlsx');

      /**
       *
       * WORKSHEET PARA REPORTE DE CONSOLIDADO
       */
      let excel_parametros = [];

      //fechas
      excel_parametros.push({
        label: 'Fechas',
        data: `Del ${this.selectedDias[0]} al ${this.selectedDias[1]}`,
      });

      //supervisora
      if (this.searchSupervisora.length > 0) {
        excel_parametros.push({
          label: 'Usuario(s):',
          data: this.fn_arrayToCommaStringUsuarios_paraExcel(
            this.searchSupervisora,
            'nombre_completo'
          ),
        });
      }
      //DIVISIONES
      if (this.searchDivision.length > 0) {
        excel_parametros.push({
          label: 'Divisiones(s):',
          data: this.fn_arrayToCommaString_paraExcel(
            this.searchDivision,
            'division',
            'division'
          ),
        });
      }
      //REGIONES
      if (this.searchRegion.length > 0) {
        excel_parametros.push({
          label: 'Región(es):',
          data: this.fn_arrayToCommaString_paraExcel(
            this.searchRegion,
            'region',
            'region'
          ),
        });
      }
      //SECTORES
      if (this.searchSector.length > 0) {
        excel_parametros.push({
          label: 'Sector(s):',
          data: this.fn_arrayToCommaString_paraExcel(
            this.searchSector,
            'codigo',
            'codigo'
          ),
        });
      }
      //TIPOS DE AGENTE DE COMERCIO
      if (this.searchAgenteDeComercio.length > 0) {
        excel_parametros.push({
          label: 'Tipos de agente(s) de comercio:',
          data: this.fn_arrayToCommaString_paraExcel(
            this.searchAgenteDeComercio,
            null,
            null
          ),
        });
      }
      //CONSEJERAS
      if (this.searchConsejera.length > 0) {
        excel_parametros.push({
          label: 'Tipos de agente(s) de comercio:',
          data: this.fn_arrayToCommaString_paraExcel(
            this.searchConsejera,
            'nombre_completo',
            'nombre_completo'
          ),
        });
      }
      //REPORTES DE
      if (
        this.reporte_seleccionado_items != null &&
        this.reporte_seleccionado_items.length >= 1
      ) {
        excel_parametros.push({
          label: 'Reportes de:',
          data: this.fn_arrayToCommaString_paraExcel(
            this.reporte_seleccionado_items,
            'text',
            'text'
          ),
        });
      }
      //OTROS MOTIVO(S) DE VISITA
      if (
        this.search_motivo_de_visita_id != null &&
        this.search_motivo_de_visita_id.length >= 1
      ) {
        excel_parametros.push({
          label: 'Motivo(s) de visita:',
          data: this.fn_arrayToCommaString_paraExcel(
            this.search_motivo_de_visita_id,
            'label',
            'label'
          ),
        });
      }
      //COMENTARIO
      if (this.searchComentario != null && this.searchComentario != '') {
        excel_parametros.push({
          label: 'Observaciones:',
          data: this.searchComentario,
        });
      }

      //PROSPECTO
      if (this.searchProspecto != null && this.searchProspecto != '') {
        excel_parametros.push({
          label:
            'Buscar por nombre, dirección o teléfono de PROSPECTO que contenga:',
          data: this.searchProspecto,
        });
      }
      //NOMBRAMIENTO
      if (this.searchNombramiento != null && this.searchNombramiento != '') {
        excel_parametros.push({
          label:
            'Buscar por nombre, dirección, teléfono o número de precalificación de NOMBRAMIENTO que contenga:',
          data: this.searchNombramiento,
        });
      }
      /**
      /**
       *
       * WORKSHEET PARA REPORTE DE CONSOLIDADO FIN
       */

      this.consolidado_excel_detalle.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.fecha) - new Date(a.fecha);
      });

      console.log(this.consolidado_excel_detalle);
      // excel itself
      let data = [
        {
          sheet: 'Parámetros',
          columns: [
            { label: 'Parámetros', value: 'label' }, // Top level data
            {
              label:
                'Todos los #check-ins que cumplan las siguientes condiciones:',
              value: 'data',
            }, // Column format
            // { label: '#check-ins', value: 'data', format: '# "years"' }, // Column format
            // {
            //   label: 'Phone',
            //   value: 'user.more.phone',
            //   format: '(###) ###-####',
            // }, // Deep props and column format
          ],
          content: excel_parametros,
        },
        {
          sheet: 'Detalle',
          columns: [
            { label: 'ID', value: 'id' }, // Top level data
            { label: 'Usuario', value: 'usuario' }, // Top level data
            {
              label: 'Tipo de agente de comercio',
              value: 'tipo_agente_comercio',
            }, // Top level data
            { label: 'División', value: 'division' }, // Top level data
            { label: 'Región', value: 'region' }, // Top level data
            { label: 'Sector', value: 'sector' }, // Top level data
            { label: 'Motivo', value: 'motivo' }, // Top level data
            { label: 'Consejera código', value: 'consejera_codigo' }, // Top level data
            { label: 'Consejera nombre', value: 'consejera_nombre' }, // Top level data
            { label: 'Nombramiento', value: 'nombramiento' }, // Top level data
            { label: 'Número de precalificación', value: 'precalificacion' }, // Top level data
            { label: 'DPI', value: 'dpi' }, // Top level data
            { label: 'Fecha', value: 'fecha' }, // Top level data
            { label: 'Observación', value: 'comentario' }, // Top level data
            { label: 'Latitud', value: 'lat' }, // Top level data
            { label: 'Longitud', value: 'lng' }, // Top level data

            // { label: 'Age', value: (row) => row.age + ' years' }, // Custom format
            // {
            //   label: 'Phone',
            //   value: (row) => (row.more ? row.more.phone || '' : ''),
            // }, // Run functions
          ],
          content: this.consolidado_excel_detalle,
        },

        {
          sheet: 'Resumen por celular',
          columns: [
            { label: 'Usuario', value: 'label' }, // Top level data
            { label: '#check-ins', value: (row) => row.data[0] }, // Column format
            // { label: '#check-ins', value: 'data', format: '# "years"' }, // Column format
            // {
            //   label: 'Phone',
            //   value: 'user.more.phone',
            //   format: '(###) ###-####',
            // }, // Deep props and column format
          ],
          content: this.consolidado_excel_resumen_usuarios,
        },
        {
          sheet: 'Resumen por division',
          columns: [
            { label: 'División', value: 'label' }, // Top level data
            { label: '#check-ins', value: (row) => row.data[0] }, // Column format
          ],
          content: this.consolidado_excel_resumen_divisiones,
        },
        {
          sheet: 'Resumen por región',
          columns: [
            { label: 'Region', value: 'label' }, // Top level data
            { label: '#check-ins', value: (row) => row.data[0] }, // Column format
          ],
          content: this.consolidado_excel_resumen_regiones,
        },
        {
          sheet: 'Resumen por Sector',
          columns: [
            { label: 'Sector', value: 'label' }, // Top level data
            { label: '#check-ins', value: (row) => row.data[0] }, // Column format
          ],
          content: this.consolidado_excel_resumen_sectores,
        },
        {
          sheet: 'Resumen por Motivo',
          columns: [
            { label: 'Motivo', value: 'label' }, // Top level data
            { label: '#check-ins', value: (row) => row.data[0] }, // Column format
          ],
          content: this.consolidado_excel_resumen_motivos,
        },
      ];

      let settings = {
        fileName: `Consolidado Del ${this.selectedDias[0]} al ${this.selectedDias[1]}`, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings); // Will download the excel file
    },

    ...mapActions('Visitas', {
      findVisitas: 'find',
    }),
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
    }),
    ...mapActions('Consejeras', {
      findConsejeras: 'find',
    }),
    ...mapActions('ReporteResumenConsejerasPorRegion', {
      findRRCPR: 'find',
    }),
    getDate(date) {
      return date ? moment(date).utc().format('DD MMM  HH:mm a') : '';
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },

    getParamasAutoCompleteSupervisoras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSupervisoras
       */
      const params = {};
      params['$limit'] = 50;
      params['region'] = { $ne: '' };
      params['$select'] = [
        'id',
        'nombre_completo',
        'codigo',
        'alias',
        'tipo',
        'division',
        'region',
      ];
      if (this.searchSyncSupervisoras != '') {
        params['$or'] = [
          {
            region: { $like: this.searchSyncSupervisoras + '%' },
          },
          {
            codigo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            region: { $like: this.searchSyncSupervisoras + '%' },
          },
          {
            alias: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
        ];
      }

      // if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
      //   console.log(
      //     'sus sectores ',
      //     this.$store.state.auth.payload.sus_sectores
      //   );
      //   params['codigo'] = {
      //     $in: this.$store.state.auth.payload.sus_sectores,
      //   };
      // }
      /** mostrar solo suuarios de tipo "supervisora" y "adinistrativo" */

      /** orderar por codigo */
      params['$sort'] = { codigo: 1, region: 1 };

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutoCompleteConsejeras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteConsejeras
       */
      const params = {};
      params['$select'] = ['nombre_completo', 'codigo'];
      if (this.searchSyncConsejeras != '') {
        params['$or'] = [
          {
            codigo: { $like: '%' + this.searchSyncConsejeras + '%' },
          },
          { nombre_completo: { $like: '%' + this.searchSyncConsejeras + '%' } },
        ];
      }

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['fk_id_supervisora'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteSectores() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSectores
       */
      const params = {};
      params['sesion_rol'] = 2;
      params['$select'] = ['codigo'];
      params['$limit'] = 3;
      params['$sort'] = { codigo: 1 };

      if (this.searchSyncSectores != '') {
        params['codigo'] = { $like: '%' + this.searchSyncSectores + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteDivisiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteDivisiones
       */
      const params = {};
      params['$select'] = ['division'];
      params['$limit'] = 3;
      params['$sort'] = { division: 0 };

      if (this.searchSyncDivisiones != '') {
        params['division'] = { $like: '%' + this.searchSyncDivisiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteRegiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};
      params['$select'] = ['region'];
      params['$limit'] = 3;
      params['$sort'] = { region: 1 };

      if (this.searchSyncRegiones != '') {
        params['region'] = { $like: this.searchSyncRegiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },

    fnGetAutocompleteSupervisoras() {
      //
      this.findUsuarios({
        query: this.getParamasAutoCompleteSupervisoras(),
      }).then((result) => {
        result.data.forEach((supervisora) => {
          supervisora.nombre_completo =
            supervisora.alias +
            ' - ' +
            supervisora.tipo +
            ' - ' +
            supervisora.division +
            ' - ' +
            supervisora.region;
        });
        this.loadingSupervisoras = false;
        this.itemsSupervisoras = result.data;
      });
      //
    },
    fnGetAutocompleteConsejeras() {
      //
      this.findConsejeras({
        query: this.getParamasAutoCompleteConsejeras(),
      }).then((result) => {
        result.data.forEach((consejera) => {
          consejera.nombre_completo =
            consejera.codigo + ' ' + consejera.nombre_completo;
        });
        this.loadingConsejeras = false;
        this.itemsConsejeras = result.data;
      });
      //
    },
    fnGetAutocompleteSectores() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteSectores(),
      }).then((result) => {
        this.loadingSectores = false;
        this.itemsSectores = result.data;
      });
      //
    },
    fnGetAutocompleteDivisiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteDivisiones(),
      }).then((result) => {
        this.loadingDivisiones = false;
        this.itemsDivisiones = result.data;
      });
      //
    },
    fnGetAutocompleteRegiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteRegiones(),
      }).then((result) => {
        this.loadingRegiones = false;
        this.itemsRegiones = result.data;
      });
      //
    },
    fn_arrayToCommaStringUsuarios(arrayObjects, title, propertie) {
      return Array.prototype.map
        .call(arrayObjects, function (item) {
          return `<span title="${item[title]}"><span class="mdi mdi-cellphone"></span> ${item[propertie]}</span>`;
        })
        .join(',');
    },
    fn_arrayToCommaStringUsuarios_paraExcel(arrayObjects, title) {
      return Array.prototype.map
        .call(arrayObjects, function (item) {
          return `Usuario ${item[title]}`;
        })
        .join(', ');
    },
    fn_arrayToCommaString(arrayObjects, title, propertie) {
      return Array.prototype.map
        .call(arrayObjects, function (item) {
          if (title == null) {
            return item;
          }
          return `<span title="${item[title]}">${item[propertie]}</span>`;
        })
        .join(',');
    },
    fn_arrayToCommaString_paraExcel(arrayObjects, title, propertie) {
      return Array.prototype.map
        .call(arrayObjects, function (item) {
          if (title == null) {
            return item;
          }
          return `${item[propertie]}`;
        })
        .join(', ');
    },
  },
  computed: {
    flag_formulario_con_parametros() {
      if (
        this.searchSupervisora.length > 0 ||
        this.searchAgenteDeComercio.length > 0 ||
        this.searchConsejera.length > 0 ||
        this.searchDivision.length > 0 ||
        this.searchRegion.length > 0 ||
        this.searchSector.length > 0 ||
        (this.search_motivo_de_visita_id != null &&
          this.search_motivo_de_visita_id.length >= 1) ||
        (this.searchComentario != '' && this.searchComentario != null) ||
        (this.searchProspecto != '' && this.searchProspecto != null) ||
        (this.searchNombramiento != '' && this.searchNombramiento != null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    localStoreMotivos() {
      return this.$store.state.visitas;
    },
    selectedMes() {
      return this.dialogVarSelectedMes
        ? moment(this.dialogVarSelectedMes).format('MMM-YYYY')
        : moment().format('MMM-YYYY');
    },
    dialogVarMinDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    dialogVarMaxDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
    parametros_state() {
      // parametros para visita
      return this.data;

      // parametros para consejeras

      // parametros para Motivo de visita
    },
    texto_para_comentario_titulo() {
      if (this.reporte_seleccionado.text == 'Reuniones') {
        return 'Buscar por alguna palabra contenida en la descripción de las reuniones';
      }
      if (this.reporte_seleccionado.text == 'Inicios de labores') {
        return 'Buscar por alguna palabra contenida en las observaciones en los inicios de labores';
      }
      if (this.reporte_seleccionado.text == 'Prospecto') {
        return 'Buscar por alguna palabra contenida en las observaciones';
      }
      return 'Buscar por alguna palabra contenida en las observaciones de #check-ins';
    },
    texto_para_comentario_label() {
      if (this.reporte_seleccionado.text == 'Reuniones') {
        return 'Descripción';
      }
      if (this.reporte_seleccionado.text == 'Inicios de labores') {
        return 'Observaciones';
      }
      if (this.reporte_seleccionado.text == 'Prospecto') {
        return 'Observaciones';
      }
      return 'Observaciones';
    },
  },
};
</script>
