<template>
  <v-container class="" fluid>
    <v-row align="center" class="px-3 mx-auto">
      <v-col>
        <v-card>
          <v-toolbar color="cyan" dark flat>
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

            <v-toolbar-title> #check-ins</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              @click="fnRefreshReportes"
              icon
              :title="'ultima actualizacon ' + fechaUltimaActualizacion"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab v-for="item in items" :key="item"> Consolidado </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item">
              <v-card flat>
                <!-- prettier-ignore -->
                <ReporteGeneral :prop_refresh_flag="prop_refresh_flag" v-if="item == 'General'" ></ReporteGeneral>
                <!-- prettier-ignore -->
                <!-- <ReporteDiario :prop_refresh_flag="prop_refresh_flag" v-if="item == 'Diario'" /> -->
                <!-- prettier-ignore -->
                <!-- <ReporteSemanal :prop_refresh_flag="prop_refresh_flag" v-if="item == 'Semanal'" /> -->
                <!-- prettier-ignore -->
                <!-- <ReporteMensual :prop_refresh_flag="prop_refresh_flag" v-if="item == 'Mensual'" /> -->
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
import ReporteGeneral from './general.vue';
// import ReporteDiario from './diario.vue';
// import ReporteSemanal from './semanal.vue';
// import ReporteMensual from './mensual.vue';
export default {
  data() {
    return {
      prop_refresh_flag: 1,
      tab: null,
      //items: ['General', 'Diario', 'Semanal', 'Mensual'],
      items: ['General'],
      text: '',
      fechaUltimaActualizacion: moment().format('dd MMM HH:mm a'),
    };
  },
  components: {
    ReporteGeneral,
    // ReporteDiario,
    // ReporteMensual,
    // ReporteSemanal,
  },
  methods: {
    fnRefreshReportes() {
      this.prop_refresh_flag++;
      this.fechaUltimaActualizacion = moment().format('dd MMM HH:mm a');
    },
  },
};
</script>
