<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <h1>{{ reporte_seleccionado[0].text }}</h1> -->

        <GeneralParametros
          :consolidado_excel_detalle="consolidado_excel_detalle"
          :consolidado_excel_resumen_usuarios="usuarios"
          :consolidado_excel_resumen_divisiones="divisiones"
          :consolidado_excel_resumen_regiones="regiones"
          :consolidado_excel_resumen_sectores="sectores"
          :consolidado_excel_resumen_motivos="
            consolidado_grafica_tipos_de_visita
          "
          @EmitMotivoDeVisitaId="fnEmitMotivoDeVisitaId"
          @EmitSelectedDate="fnEmitSelectedDate"
          @EmitSearchSupervisora="fnEmitSearchSupervisora"
          @EmitsearchAgenteDeComercio="fnEmitsearchAgenteDeComercio"
          @EmitSearchConsejera="fnEmitSearchConsejera"
          @EmitSearchSector="fnEmitSearchSector"
          @EmitSearchDivision="fnEmitSearchDivision"
          @EmitSearchRegion="fnEmitSearchRegion"
          @EmitSearchComentario="fnEmitSearchComentario"
          @EmitSearchProspecto="fnEmitSearchProspecto"
          @EmitSearchNombramiento="fnEmitSearchNombramiento"
          @EmitAplicarFiltros="main()"
          @EmitReporteSeleccionado="fnEmitReporteSeleccionado"
        />
        <v-fab-transition>
          <v-btn
            class="mb-3 elevation-10"
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="grid = !grid"
          >
            <v-icon v-if="grid == true">mdi-view-grid</v-icon>
            <v-icon v-if="grid == false">mdi-view-list</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col :cols="grid == true ? '6' : '12'">
            <GeneralGraficaUsuarios
              :prop_data="usuarios"
            ></GeneralGraficaUsuarios>
          </v-col>
          <v-col :cols="grid == true ? '6' : '12'">
            <GeneralGraficaRegiones
              :prop_data="regiones"
            ></GeneralGraficaRegiones>
          </v-col>
          <v-col :cols="grid == true ? '6' : '12'">
            <GeneralGraficaSectores
              :prop_data="sectores"
            ></GeneralGraficaSectores>
          </v-col>
          <v-col :cols="grid == true ? '6' : '12'">
            <GeneralGraficaDivisiones
              :prop_data="divisiones"
            ></GeneralGraficaDivisiones>
          </v-col>
          <v-col :cols="grid == true ? '12' : '12'">
            <GeneralGraficaTiposDeVisita
              :prop_tipos_de_visitas_totales="
                consolidado_grafica_tipos_de_visita
              "
            ></GeneralGraficaTiposDeVisita>
          </v-col>
          <v-col :cols="grid == true ? '12' : '12'" style="">
            <VisitasMapa
              :prop_markers="consolidado_excel_detalle"
              :prop_polyline_enable="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import GeneralParametros from './generalParametros.vue';
import GeneralGraficaUsuarios from './generalGraficaUsuarios.vue';
import GeneralGraficaSectores from './generalGraficaSectores.vue';
import GeneralGraficaRegiones from './generalGraficaRegiones.vue';
import GeneralGraficaDivisiones from './generalGraficaDivisiones.vue';
import GeneralGraficaTiposDeVisita from './generalGraficaTiposDeVisita.vue';
import VisitasMapa from './../../components/mapa_consolidado.vue';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'reporteGeneral',
  components: {
    VisitasMapa,
    GeneralParametros,
    GeneralGraficaUsuarios,
    GeneralGraficaSectores,
    GeneralGraficaRegiones,
    GeneralGraficaDivisiones,
    GeneralGraficaTiposDeVisita,
  },
  props: ['prop_refresh_flag'],
  watch: {
    prop_refresh_flag() {
      this.main();
    },
    tipos_de_visita_raw(newValue) {
      let consolidado_por_tipo_de_visita = [];
      for (
        let index = 0;
        index < Object.keys(newValue.labels).length;
        index++
      ) {
        let backgroundColor = this.fnGetBackground(newValue.labels[index]);
        let borderColor = this.fnGetBorder(newValue.labels[index]);
        const visitas_agrupadas_por_motivo = {
          label: newValue.labels[index],
          data: [newValue.cantidades[index]],
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        };
        consolidado_por_tipo_de_visita.push(visitas_agrupadas_por_motivo);
      }
      this.consolidado_grafica_tipos_de_visita = consolidado_por_tipo_de_visita;
    },
    divisiones_raw(newValue) {
      let background_b_tone = 172;
      let background_tone = 0.7;
      let resp_consolidado_por_division = [];
      for (
        let index = 0;
        index < Object.keys(newValue.labels).length;
        index++
      ) {
        const visitas_agrupadas_por_division = {
          label: newValue.labels[index],
          data: [newValue.cantidades[index]],
          backgroundColor: `rgba(77, 182, ${background_b_tone}, ${background_tone})`,
          borderColor: '#4DB6AC',
        };
        resp_consolidado_por_division.push(visitas_agrupadas_por_division);

        background_b_tone -= 12;
      }
      this.divisiones = resp_consolidado_por_division;
    },
    usuarios_raw(newValue) {
      let background_b_tone = 252;
      let background_tone = 0.7;
      let response_consolidadoUsuarios = [];
      for (
        let index = 0;
        index < Object.keys(newValue.labels).length;
        index++
      ) {
        const visitas_agrupadas_por_usuario = {
          label: `usuario: ${newValue.labels[index]}`,
          data: [newValue.cantidades[index]],
          backgroundColor: `rgba(179, 199, ${background_b_tone}, ${background_tone})`,
          borderColor: '#0700FF',
        };
        response_consolidadoUsuarios.push(visitas_agrupadas_por_usuario);
        background_tone += 0.05;
        if (background_tone > 1) {
          background_b_tone -= 10;
        }
      }
      this.usuarios = response_consolidadoUsuarios;
    },
    regiones_raw(newValue) {
      let background_b_tone = 179;
      let background_tone = 0.7;
      let response_consolidadoUsuarios = [];
      for (
        let index = 0;
        index < Object.keys(newValue.labels).length;
        index++
      ) {
        const visitas_agrupadas_por_usuario = {
          label: `${newValue.labels[index]}`,
          data: [newValue.cantidades[index]],
          backgroundColor: `rgba(252, 184, ${background_b_tone}, ${background_tone})`,
          borderColor: '#0700FF',
        };
        response_consolidadoUsuarios.push(visitas_agrupadas_por_usuario);
        background_tone += 0.05;
        if (background_tone > 1) {
          background_b_tone -= 10;
        }
      }
      this.regiones = response_consolidadoUsuarios;
    },
    sectores_raw(newValue) {
      let background_b_tone = 252;
      let background_tone = 0.7;
      let response_consolidadoSectores = [];
      for (
        let index = 0;
        index < Object.keys(newValue.labels).length;
        index++
      ) {
        const visitas_agrupadas_por_sector = {
          label: `sec ${newValue.labels[index]}`,
          data: [newValue.cantidades[index]],
          backgroundColor: `rgba(220, 179, ${background_b_tone}, ${background_tone})`,
          borderColor: '#D800FF',
        };
        response_consolidadoSectores.push(visitas_agrupadas_por_sector);
        background_tone += 0.05;
        if (background_tone > 1) {
          background_b_tone -= 10;
        }
      }
      this.sectores = response_consolidadoSectores;
    },
  },
  data() {
    return {
      reporte_seleccionado: [
        { text: 'Visitas', icon: 'mdi-map-marker' },
        { text: 'Inicios de labores', icon: 'mdi-account-clock' },
        { text: 'Prospectos', icon: 'mdi-account-multiple-plus' },
        { text: 'Nombramientos', icon: 'mdi-card-account-details-outline' },
        { text: 'Reuniones', icon: 'mdi-account-group-outline' },
        { text: 'Actividades', icon: 'mdi-map-marker' },
      ],
      grid: true,
      // varoabñes para exportar excel
      consolidado_excel_detalle: [],
      // variables para graficas
      divisiones: [],
      divisiones_raw: { labels: [], cantidades: [], data: [] },
      regiones: [],
      regiones_raw: { labels: [], cantidades: [], data: [] },
      sectores: [],
      sectores_raw: { labels: [], cantidades: [], data: [] },
      usuarios: [],
      usuarios_raw: { labels: [], cantidades: [], data: [] },
      tipos_de_visita_raw: { labels: [], cantidades: [], data: [] },
      consolidado_grafica_tipos_de_visita: [],
      // variables para parametrización de API's
      params: {
        search_motivo_de_visita_id: null,
        searchSupervisora: null,
        searchAgenteDeComercio: null,
        searchConsejera: null,
        searchSector: null,
        searchDivision: null,
        searchRegion: null,
        searchComentario: null,
        searchProspecto: null,
        searchNombramiento: null,
        searchSelectedDate: [
          moment().startOf('month').format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
        ],
      },
      items: [],
    };
  },
  methods: {
    ...mapActions('Visitas', {
      findVisitas: 'find',
    }),
    ...mapActions('Nombramientos', {
      findNombramientos: 'find',
    }),
    ...mapActions('Prospectos', {
      findProspectos: 'find',
    }),
    ...mapActions('SupervInicioDeLabores', {
      findSupervInicioDeLabores: 'find',
    }),
    ...mapActions('SupervReunionConsejeras', {
      findSupervReunionConsejeras: 'find',
    }),
    ...mapActions('Actividades', {
      findActividades: 'find',
    }),
    fnEmitMotivoDeVisitaId(newValue) {
      console.log('los motivos', newValue);
      this.params.search_motivo_de_visita_id = newValue;
      this.main();
    },
    fnEmitSelectedDate(newValue) {
      console.log('las fechas', newValue);
      this.params.searchSelectedDate = newValue;
      this.main();
    },
    fnEmitSearchSupervisora(newValue) {
      console.log('los usuarios', newValue);
      this.params.searchSupervisora = newValue;
      this.main();
    },
    fnEmitsearchAgenteDeComercio(newValue) {
      console.log('los agentes de comercio', newValue);
      this.params.searchAgenteDeComercio = newValue;
      this.main();
    },
    fnEmitSearchConsejera(newValue) {
      console.log('las consejeras', newValue);
      this.params.searchConsejera = newValue;
      this.main();
    },
    fnEmitSearchSector(newValue) {
      console.log('los sectores', newValue);
      this.params.searchSector = newValue;
      this.main();
    },
    fnEmitSearchDivision(newValue) {
      console.log('las divisiones', newValue);
      this.params.searchDivision = newValue;
      this.main();
    },
    fnEmitSearchRegion(newValue) {
      console.log('las regiones', newValue);
      this.params.searchRegion = newValue;
      this.main();
    },
    fnEmitSearchComentario(newValue) {
      console.log('el comentario', newValue);
      this.params.searchComentario = newValue;
      this.main();
    },
    fnEmitSearchProspecto(newValue) {
      console.log('el prospecto', newValue);
      this.params.searchProspecto = newValue;
      this.main();
    },
    fnEmitSearchNombramiento(newValue) {
      console.log('el nombramiento', newValue);
      this.params.searchNombramiento = newValue;
      this.main();
    },
    fnEmitReporteSeleccionado(newValue) {
      this.reporte_seleccionado = newValue;
      this.main();
    },
    // funcion principal, se llama por cada cambio
    async main() {
      this.consolidado_excel_detalle = [];
      //
      this.$store.state.globalLoading = true;
      //
      this.tipos_de_visita_raw = { labels: [], cantidades: [], data: [] };
      this.usuarios_raw = { labels: [], cantidades: [], data: [] };
      this.regiones_raw = { labels: [], cantidades: [], data: [] };
      this.sectores_raw = { labels: [], cantidades: [], data: [] };
      this.divisiones_raw = { labels: [], cantidades: [], data: [] };

      // custom parametros
      let params_visitas = this.getParams();
      let params_prospectos = this.getParams();
      let params_nombramientos = this.getParams();
      let params_reuniones = this.getParams();
      let params_labores = this.getParams();
      let params_actividades = this.getParams();

      // 1. RANGO DE FECHAS
      let split1 = this.params.searchSelectedDate[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,23, 59, 59);

      let split2 = this.params.searchSelectedDate[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);

      params_visitas['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };
      params_prospectos['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };
      params_nombramientos['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };
      params_reuniones['$or'] = [
        { fecha_llegada: { $gte: firstDay, $lte: lastDay } },
        { fecha_inicio: { $gte: firstDay, $lte: lastDay } },
      ];

      params_labores['$or'] = [
        { fecha_inicio: { $gte: firstDay, $lte: lastDay } },
        { inic_lab_fecha: { $gte: firstDay, $lte: lastDay } },
      ];
      params_actividades['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };

      /** parametro generico para comentarios */
      /** sacar parametro de observaciones, observacion o descripción según api */
      // 8. COMENTARIO

      if (this.params.searchComentario && this.params.searchComentario != '') {
        // este campo se  usa en: visitas, prospectos, inicio de labores
        params_visitas['observaciones'] = {
          $like: '%' + this.params.searchComentario + '%',
        };
        params_prospectos['observaciones'] = {
          $like: '%' + this.params.searchComentario + '%',
        };
        params_labores['observaciones'] = {
          $like: '%' + this.params.searchComentario + '%',
        };
        // este campo se  usa en: actividades
        params_actividades['observacion'] = {
          $like: '%' + this.params.searchComentario + '%',
        };
        // este campo se  usa en: reuniones
        params_reuniones['descripcion'] = {
          $like: '%' + this.params.searchComentario + '%',
        };
      }
      /** parametro generico para fecha */
      //TODO: en vez de createdAt que sea el respectivo campo de "fecha"

      /** parametro único para visitas */
      /** search por motivo de visita */
      if (
        this.params.search_motivo_de_visita_id != null &&
        this.params.search_motivo_de_visita_id.length >= 1
      ) {
        let arrayOfIds = this.params.search_motivo_de_visita_id.map(
          (a) => a.id
        );
        params_visitas['motivo_de_visita_id'] = arrayOfIds;
      }

      // VISITAS

      let val_visitas_check = this.reporte_seleccionado.findIndex((object) => {
        return object.text == 'Visitas';
      });
      if (val_visitas_check > -1) {
        await this.findVisitas({ query: params_visitas })
          .then((result) => {
            // result
            console.log('result VISITAS', result);
            result.data.forEach((element) => {
              // consolidado para el excel
              const consolidado_detalle = {
                id: element.id,
                lat: element.lat,
                lng: element.lng,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita,
                consejera_codigo: element.codigo_consejera,
                consejera_nombre: element.consejera_nombre,
                nombramiento: null,
                precalificacion: null,
                dpi: null,
                fecha: element.fecha,
                comentario: element.comentario,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });

            //
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            // prettier-ignore
            this.divisiones_raw = this.groupBy_divisiones(result.data,'division');
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
            //console.log('agrupando datos en visitas ', result.data);
            //console.log('los datos agrupados DIVISIONES', this.usuarios_raw);
          })
          .catch((e) => {
            console.log(e);
          });
      }

      // NOMBRAMIENTOS
      let val_nombramiento_check = this.reporte_seleccionado.findIndex(
        (object) => {
          return object.text == 'Nombramientos';
        }
      );
      if (val_nombramiento_check > -1) {
        await this.findNombramientos({ query: params_nombramientos })
          .then((result) => {
            // result
            console.log('result findNombramientos', result);
            result.data.forEach((element) => {
              element.motivo_de_visita = 'Nombramiento';
              // consolidado para el excel
              const consolidado_detalle = {
                id: element.id,
                lat: element.lat,
                lng: element.lng,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita,
                consejera_codigo: null,
                consejera_nombre: null,
                nombramiento: element.nombre,
                precalificacion: element.no_precalificacion,
                dpi: element.DPI,
                fecha: element.fecha,
                comentario: null,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });
            // agrupación de registros para consolidado
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            // prettier-ignore
            this.divisiones_raw = this.groupBy_divisiones(result.data,'division');
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.searchNombramiento = null;
      }

      // PROSPECTOS
      let val_prospectos_check = this.reporte_seleccionado.findIndex(
        (object) => {
          return object.text == 'Prospectos';
        }
      );
      if (val_prospectos_check > -1) {
        await this.findProspectos({ query: params_prospectos })
          .then((result) => {
            // result
            console.log('result findProspectos', result);
            result.data.forEach((element) => {
              element.motivo_de_visita = 'Prospecto';
              // consolidado para el excel
              const consolidado_detalle = {
                id: element.id,
                lat: element.lat,
                lng: element.lng,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita,
                consejera_codigo: null,
                consejera_nombre: null,
                nombramiento: element.nombre,
                precalificacion: null,
                dpi: null,
                fecha: element.fecha,
                comentario: element.observaciones,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });
            // agrupación de registros para consolidado
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            this.divisiones_raw = this.groupBy_divisiones(
              result.data,
              'division'
            );
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.searchProspecto = null;
      }

      // INICIO DE LABORES
      let val_labores_check = this.reporte_seleccionado.findIndex((object) => {
        return object.text == 'Inicios de labores';
      });
      if (val_labores_check > -1) {
        await this.findSupervInicioDeLabores({ query: params_labores })
          .then((result) => {
            // result
            console.log('result findSupervInicioDeLabores', result);
            result.data.forEach((element) => {
              element.motivo_de_visita = 'Inicio de labores';
              // consolidado para el excel

              let lato = null;
              let lngo = null;

              if (element.lat && element.lng) {
                lato = element.lat;
                lngo = element.lng;
              } else if (element.inic_lab_lat && element.inic_lab_lng) {
                lato = element.inic_lab_lat;
                lngo = element.inic_lab_lng;
              }

              const consolidado_detalle = {
                id: element.id,
                lat: lato,
                lng: lngo,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita,
                consejera_codigo: null,
                consejera_nombre: null,
                nombramiento: null,
                precalificacion: null,
                dpi: null,
                fecha: element.fecha_inicio,
                comentario: element.observaciones,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });
            // agrupación de registros para consolidado
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            this.divisiones_raw = this.groupBy_divisiones(
              result.data,
              'division'
            );
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }

      // REUNIONES
      let val_reuniones_check = this.reporte_seleccionado.findIndex(
        (object) => {
          return object.text == 'Reuniones';
        }
      );
      if (val_reuniones_check > -1) {
        await this.findSupervReunionConsejeras({ query: params_reuniones })
          .then((result) => {
            // result
            console.log('result findSupervReunionConsejeras', result);
            result.data.forEach((element) => {
              element.motivo_de_visita = 'Reuniones';
              // consolidado para el excel
              const consolidado_detalle = {
                id: element.id,
                lat: element.lat_inicio,
                lng: element.lng_inicio,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita,
                consejera_codigo: null,
                consejera_nombre: null,
                nombramiento: null,
                precalificacion: null,
                dpi: null,
                fecha: element.fecha_llegada,
                comentario: element.descripcion,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });
            // agrupación de registros para consolidado
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            this.divisiones_raw = this.groupBy_divisiones(
              result.data,
              'division'
            );
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }

      // ACTIVIDADES
      let val_actividades_check = this.reporte_seleccionado.findIndex(
        (object) => {
          return object.text == 'Actividades';
        }
      );
      if (val_actividades_check > -1) {
        await this.findActividades({ query: params_actividades })
          .then((result) => {
            // result
            console.log('result findActividades', result);
            result.data.forEach((element) => {
              element.motivo_de_visita = 'Actividades';
              // consolidado para el excel
              const consolidado_detalle = {
                id: element.id,
                lat: element.lat,
                lng: element.lng,
                usuario: element.supervisora.alias,
                tipo_agente_comercio: element.tipo,
                division: element.division,
                region: element.region,
                sector: element.sector,
                motivo: element.motivo_de_visita + ' / ' + element.actividad,
                consejera_codigo: null,
                consejera_nombre: null,
                nombramiento: null,
                precalificacion: null,
                dpi: null,
                fecha: element.fecha,
                comentario: element.observacion,
              };

              this.consolidado_excel_detalle.push(consolidado_detalle);
              //fin consolidado excel
            });
            // agrupación de registros para consolidado
            // prettier-ignore
            this.tipos_de_visita_raw = this.groupBy_tipos_de_visita(result.data, 'motivo_de_visita');
            this.divisiones_raw = this.groupBy_divisiones(
              result.data,
              'division'
            );
            this.regiones_raw = this.groupBy_regiones(result.data, 'region');
            this.sectores_raw = this.groupBy_sectores(result.data, 'sector');
            this.usuarios_raw = this.groupBy_usuario_alias(
              result.data,
              'alias'
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }

      //Datos
      this.$store.state.globalLoading = false;
    },
    // parametrización de todas las APIS'
    getParams() {
      /**
       * configuración de parametros
       */
      const params = {};
      //fechas para feathers

      ///
      //params['id_usuario'] = { $nin: [1] };

      // 2. SUPERVISORAS
      // prettier-ignore
      if (this.params.searchSupervisora && this.params.searchSupervisora.length > 0) {
        let arrayOfIds = this.params.searchSupervisora.map((a) => a.id);
        params['id_usuario'] = { $in: arrayOfIds };
      }else if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['id_usuario'] = {
          $in: this.$store.state.auth.payload.sus_sectores,
        };
      }
      // 3. TIPO DE AGENTE DE COMERCIO
      // prettier-ignore
      if (this.params.searchAgenteDeComercio && this.params.searchAgenteDeComercio.length > 0) {
        params['tipo'] = { $in: this.params.searchAgenteDeComercio };
      }
      // 4. CONSEJERAS (ONLY FOR VISITAS)
      // prettier-ignore
      if (this.params.searchConsejera && this.params.searchConsejera.length > 0) {
        let arrayOfIds = this.params.searchConsejera.map((a) => a.codigo);
        params['codigo_consejera'] = { $in: arrayOfIds };
      }

      // 5. DIVISION
      // prettier-ignore
      if (this.params.searchDivision && this.params.searchDivision.length > 0) {
        let arrayOfIds = this.params.searchDivision.map(function (a) {
          return a.division;
        });
        params['division'] = { $in: arrayOfIds };
      }
      // 6. REGION
      // prettier-ignore
      if (this.params.searchRegion && this.params.searchRegion.length > 0) {
        let arrayOfIds = this.params.searchRegion.map((a) => a.region);
        params['region'] = { $in: arrayOfIds };
      }
      //
      // 7. SECTOR
      // prettier-ignore
      if (this.params.searchSector && this.params.searchSector.length > 0) {
        console.log('el sector',this.params.searchSector);
        let arrayOfIds = this.params.searchSector.map((a) => a.codigo);
        params['sector'] = { $in: arrayOfIds };

        
      }

      // 9. PROSPECTOS
      if (this.params.searchProspecto && this.params.searchProspecto != '') {
        params['$or'] = [
          {
            nombre: { $like: '%' + this.params.searchProspecto + '%' },
          },
          {
            telefono: { $like: '%' + this.params.searchProspecto + '%' },
          },
          {
            direccion: { $like: '%' + this.params.searchProspecto + '%' },
          },
        ];
      }
      // 10. NOMBRAMIENTOS
      if (
        this.params.searchNombramiento &&
        this.params.searchNombramiento != ''
      ) {
        params['$or'] = [
          {
            nombre: { $like: '%' + this.params.searchNombramiento + '%' },
          },
          {
            direccion: { $like: '%' + this.params.searchNombramiento + '%' },
          },
          {
            telefono: { $like: '%' + this.params.searchNombramiento + '%' },
          },
          {
            no_precalificacion: {
              $like: '%' + this.params.searchNombramiento + '%',
            },
          },
          {
            dpi: { $like: '%' + this.params.searchNombramiento + '%' },
          },
        ];
      }
      //

      params['$limit'] = 7500;
      // params['$skip'] = (this.page - 1) * this.pageSize;
      params['$sort'] = { createdAt: 0 };

      /** parametrización de supervisoras asignadas a usuario administrativo */

      /**
       *
       * retorno de parametros
       */

      // console.log('los parametros', params);

      return params;
    },
    // agrupacion de datos, hacer un consolidado de todas las api a nivel de:
    groupBy(collection, property) {
      var i = 0,
        val,
        index,
        values = [],
        result = [];
      for (; i < collection.length; i++) {
        val = collection[i][property]; /// propiedad del registro
        index = values.indexOf(val); // ver si existe en (values)
        if (index > -1) result[index].push(collection[i]);
        // si existe lo mete en el arreglo que corresponde
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      }
      let cantidades = [];
      result.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = { labels: values, cantidades: cantidades, result: result };
      return response;
    },
    groupBy_tipos_de_visita(collection, property) {
      var i = 0,
        val,
        index;
      for (; i < collection.length; i++) {
        val = collection[i][property]; /// propiedad del registro

        index = this.tipos_de_visita_raw.labels.indexOf(val); // ver si existe en (this.tipos_de_visita_raw.labels)
        if (index > -1)
          this.tipos_de_visita_raw.data[index].push(collection[i]);
        // si existe lo mete en el arreglo que corresponde
        else {
          this.tipos_de_visita_raw.labels.push(val);
          this.tipos_de_visita_raw.data.push([collection[i]]);
        }
      }
      let cantidades = [];
      this.tipos_de_visita_raw.data.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = {
        labels: this.tipos_de_visita_raw.labels,
        cantidades: cantidades,
        data: this.tipos_de_visita_raw.data,
      };

      return response;
    },
    groupBy_regiones(collection, property) {
      var i = 0,
        val,
        index;
      for (; i < collection.length; i++) {
        if (
          collection[i][property] != undefined &&
          collection[i][property] != '' &&
          collection[i][property].slice(0, 3) != 'Div'
        ) {
          val = collection[i][property]; /// propiedad del registro

          index = this.regiones_raw.labels.indexOf(val); // ver si existe en (this.regiones_raw.labels)
          if (index > -1) this.regiones_raw.data[index].push(collection[i]);
          // si existe lo mete en el arreglo que corresponde
          else {
            this.regiones_raw.labels.push(val);
            this.regiones_raw.data.push([collection[i]]);
          }
        }
      }
      let cantidades = [];
      this.regiones_raw.data.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = {
        labels: this.regiones_raw.labels,
        cantidades: cantidades,
        data: this.regiones_raw.data,
      };

      return response;
    },
    groupBy_sectores(collection, property) {
      var i = 0,
        val,
        index;
      for (; i < collection.length; i++) {
        if (
          collection[i][property] != undefined &&
          collection[i][property] != ''
        ) {
          val = collection[i][property]; /// propiedad del registro

          index = this.sectores_raw.labels.indexOf(val); // ver si existe en (this.sectores_raw.labels)
          if (index > -1) this.sectores_raw.data[index].push(collection[i]);
          // si existe lo mete en el arreglo que corresponde
          else {
            this.sectores_raw.labels.push(val);
            this.sectores_raw.data.push([collection[i]]);
          }
        }
      }
      let cantidades = [];
      this.sectores_raw.data.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = {
        labels: this.sectores_raw.labels,
        cantidades: cantidades,
        data: this.sectores_raw.data,
      };

      return response;
    },
    groupBy_divisiones(collection, property) {
      var i = 0,
        val,
        index;
      for (; i < collection.length; i++) {
        if (
          collection[i][property] != undefined &&
          collection[i][property] != ''
        ) {
          val = collection[i][property]; /// propiedad del registro

          index = this.divisiones_raw.labels.indexOf(val.toUpperCase()); // ver si existe en (this.divisiones_raw.labels)
          if (index > -1) this.divisiones_raw.data[index].push(collection[i]);
          // si existe lo mete en el arreglo que corresponde
          else {
            this.divisiones_raw.labels.push(val.toUpperCase());
            this.divisiones_raw.data.push([collection[i]]);
          }
        }
      }
      let cantidades = [];
      this.divisiones_raw.data.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = {
        labels: this.divisiones_raw.labels,
        cantidades: cantidades,
        data: this.divisiones_raw.data,
      };

      return response;
    },
    groupBy_usuario_alias(collection, property) {
      var i = 0,
        val,
        index;
      for (; i < collection.length; i++) {
        val = collection[i]['supervisora'][property]; /// propiedad del registro
        index = this.usuarios_raw.labels.indexOf(val); // ver si existe en (this.usuarios_raw.labels)
        if (index > -1) this.usuarios_raw.data[index].push(collection[i]);
        // si existe lo mete en el arreglo que corresponde
        else {
          this.usuarios_raw.labels.push(val);
          this.usuarios_raw.data.push([collection[i]]);
        }
      }
      let cantidades = [];
      this.usuarios_raw.data.forEach((registrso) => {
        cantidades.push(Object.keys(registrso).length);
      });
      let response = {
        labels: this.usuarios_raw.labels,
        cantidades: cantidades,
        data: this.usuarios_raw.data,
      };

      return response;
    },
    /** colores en grafica motivos de visita */
    fnGetBackground(label) {
      let store_visitas = this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      );

      if (store_visitas.length > 0) {
        return store_visitas[0].background;
      } else {
        let store_otras_visitas = this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.label == label
        )[0].background;

        return store_otras_visitas;
      }
    },
    fnGetBorder(label) {
      let store_visitas = this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      );

      if (store_visitas.length > 0) {
        return store_visitas[0].border;
      } else {
        let store_otras_visitas = this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.label == label
        )[0].border;

        return store_otras_visitas;
      }
      //
    },
  },
  computed: {},
  mounted() {
    this.main();
  },
};
</script>
