import { render, staticRenderFns } from "./generalGraficaDivisiones.vue?vue&type=template&id=d4f5f78a&scoped=true&"
import script from "./generalGraficaDivisiones.vue?vue&type=script&lang=js&"
export * from "./generalGraficaDivisiones.vue?vue&type=script&lang=js&"
import style0 from "./generalGraficaDivisiones.vue?vue&type=style&index=0&id=d4f5f78a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4f5f78a",
  null
  
)

export default component.exports