<template>
  <div>
    <v-btn
      @click="fnExportChartJsToPng"
      class="float-right mb-1"
      small
      elevation="2"
      color=" primary"
      text
    >
      <v-icon sm color="">mdi-download</v-icon> (PNG)</v-btn
    >
    <canvas id="general-grafica-tipos-de-visita"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'generalGraficaTiposDeVisita',
  mounted() {
    const ctx = document
      .getElementById('general-grafica-tipos-de-visita')
      .getContext('2d');
    this.myCHart = new Chart(ctx, this.motivosDeVisitaData);
    /**
     *
     */
    // main()
    // this.fnUpdateDataSet_labels(this.prop_totales);
  },
  props: ['prop_tipos_de_visitas_totales'],
  data() {
    return {
      myCHart: null,
      myChartBase64: '',
      motivosDeVisitaData: {
        type: 'bar',
        data: {
          labels: ['#check-ins por motivo'],
          datasets: [
            {
              label: 'Primer pedido',
              data: [811],
              backgroundColor: this.fnGetBackground('Primer pedido'),
              borderColor: this.fnGetBorder('Primer pedido'),
            },
            {
              label: 'Segundo pedido',
              data: [938],
              backgroundColor: this.fnGetBackground('Segundo pedido'),
              borderColor: this.fnGetBorder('Segundo pedido'),
            },
            {
              label: 'Reingreso',
              data: [69],
              backgroundColor: this.fnGetBackground('Reingreso'),
              borderColor: this.fnGetBorder('Reingreso'),
            },
            {
              label: 'Posible baja',
              data: [374],
              backgroundColor: this.fnGetBackground('Posible baja'),
              borderColor: this.fnGetBorder('Posible baja'),
            },
            {
              label: 'Cobro',
              data: [595],
              backgroundColor: this.fnGetBackground('Cobro'),
              borderColor: this.fnGetBorder('Cobro'),
            },
            {
              label: 'Niveles',
              data: [777],
              backgroundColor: this.fnGetBackground('Niveles'),
              borderColor: this.fnGetBorder('Niveles'),
            },
            {
              label: 'Zafiros',
              data: [837],
              backgroundColor: this.fnGetBackground('Zafiros'),
              borderColor: this.fnGetBorder('Zafiros'),
            },
            {
              label: 'Regulares',
              data: [181],
              backgroundColor: this.fnGetBackground('Regulares'),
              borderColor: this.fnGetBorder('Regulares'),
            },
            {
              label: 'Ajuste',
              data: [161],
              backgroundColor: this.fnGetBackground('Ajuste'),
              borderColor: this.fnGetBorder('Ajuste'),
            },
            // {
            //   label: 'Reunion de consejeras',
            //   data: [502],
            //   backgroundColor: this.fnGetBackground('Reunion de consejeras'),
            //   borderColor: this.fnGetBorder('Reunion de consejeras'),
            // },
            {
              label: 'Correteo',
              data: [566],
              backgroundColor: this.fnGetBackground('Correteo'),
              borderColor: this.fnGetBorder('Correteo'),
            },
            {
              label: 'Prospecto de reingreso',
              data: [709],
              backgroundColor: this.fnGetBackground('Prospecto de reingreso'),
              borderColor: this.fnGetBorder('Prospecto de reingreso'),
            },
          ],
        },
      },
    };
  },

  watch: {
    prop_tipos_de_visitas_totales(newValue) {
      this.motivosDeVisitaData.data.datasets = newValue;
      this.myCHart.update();
    },
    // prop_totales(newValue) {
    //   if (newValue) {
    //     this.fnUpdateDataSet_labels(newValue);
    //   }
    // },
    // prop_detail_by_week(newValue) {
    //   if (newValue) {
    //     this.fnUpdateDataSet_data(newValue);
    //   } else {
    //     this.motivosDeVisitaData.data.datasets.forEach((data) => {
    //       data.data = [538];
    //     });
    //     this.myCHart.update();
    //   }
    // },
  },
  methods: {
    fnExportChartJsToPng() {
      this.myChartBase64 = this.myCHart.toBase64Image();

      var a = document.createElement('a'); //Create <a>
      a.href = this.myChartBase64; //Image Base64 Goes here
      a.download = 'Image.png'; //File name Here
      a.click(); //Downloaded file
    },
    fnGetBackground(label) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      )[0].background;
    },
    fnGetBorder(label) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      )[0].border;
    },
    fnUpdateDataSet_labels(newValue) {
      if (newValue) {
        this.motivosDeVisitaData.data.labels = [];
        for (let index = 1; index <= newValue.length; index++) {
          this.motivosDeVisitaData.data.labels.push('Semana ' + index);
        }
        this.myCHart.update();
      }
    },
    fnUpdateDataSet_data(newValue) {
      if (newValue.length >= 1) {
        /**
         * recorrido de semanas
         * week count = this.prop_totales.length
         */
        for (let index = 0; index < newValue.length; index++) {
          /**
           *
           * recorrido de motivos por semana
           */
          newValue[index].forEach((motivo) => {
            let dataset_index =
              this.motivosDeVisitaData.data.datasets.findIndex(
                (el) => el.label === motivo['motivo_de_visita']
              );
            if (dataset_index != -1) {
              this.motivosDeVisitaData.data.datasets[dataset_index].data[
                index
              ] = motivo['totalVisitas'];
            }
          });
        }
        this.myCHart.update();
      }
    },
  },
};
</script>
<style scoped>
#general-grafica-tipos-de-visita {
  max-height: 70vh;
}
</style>
