<template>
  <div
    class="about"
    v-on:keyup.right="fnNextVisita()"
    v-on:keyup.left="fnPrevVisita()"
  >
    <GmapMap
      :center="centerCoordenates"
      :zoom="centerZoom"
      map-type-id="terrain"
      style="width: 100%; height: 65vh"
    >
      <cluster :gridSize="70" :zoomOnClick="true" :maxZoom="9">
        <GmapMarker
          v-for="(visita, index) in prop_markers_and_pedido"
          :key="index"
          :position="{ lat: visita.lat, lng: visita.lng }"
          :clickable="true"
          :icon="visita.icon"
          :draggable="false"
          @click="toggleInfo(visita), (flagCurrentIndex = index)"
          @mouseover="fnDrawPolyPath(index)"
      /></cluster>
      <gmap-polyline
        :path="deliveryPath"
        v-bind:options="{
          strokeWeight: 5,
          strokeColor: deliveryPathColor,
          strokeOpacity: 0.7,
          icons: [
            {
              icon: {
                path: figura_camino_polyline,
                scale: 3,
                fillColor: deliveryPathColor,
                fillOpacity: 1,
              },
              offset: '0',
              repeat: '100px',
            },
          ],
        }"
        ref="polyline"
        v-on:click="poplyLineFlag = false"
      ></gmap-polyline>
      <gmap-info-window
        :options="infoOptions"
        :position="infoPosition"
        :opened="infoOpened"
        @closeclick="infoOpened = false"
      >
        <v-card v-if="infoContent" width="395" max-width="395">
          <v-card-subtitle>
            <v-icon small>mdi-cellphone</v-icon>
            {{ infoContent.usuario }}
            <small title="Tipo de agente de comercio"
              >({{ infoContent.tipo_agente_comercio }})</small
            >

            <v-btn
              class="float-right"
              title="Siguiente visita"
              :disabled="flagNextVisita"
              @click="fnNextVisita()"
              x-small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn
              class="float-right"
              title="Visita previa"
              :disabled="flagPrevVisita"
              @click="fnPrevVisita()"
              x-small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-card-text>
            <!-- CONTENIDO DE LA VENTANA INFORMATIVA -->
            <v-row>
              <v-col cols="6">
                <p v-if="infoContent.fecha">
                  <strong>Fecha:</strong>
                  <span>
                    {{ fnGetDate(infoContent.fecha) }}
                  </span>
                </p>
                <p>
                  <strong>Visita:</strong><br />
                  <span>
                    {{ infoContent.motivo }}
                    <v-icon :class="fnGetColor(infoContent.motivo)"
                      >mdi-label-variant</v-icon
                    >
                  </span>
                </p>
                <p>
                  <strong>División:</strong><br />
                  <span>
                    {{ infoContent.division }}
                  </span>
                </p>
                <p>
                  <strong>Región:</strong><br />
                  <span>
                    {{ infoContent.region }}
                  </span>
                </p>
                <p>
                  <strong>Sector:</strong><br />
                  <span>
                    {{ infoContent.sector }}
                  </span>
                </p>
              </v-col>
              <v-col>
                <p>
                  <strong>ID:</strong>
                  <span>
                    {{ infoContent.id }}
                  </span>
                </p>
                <p v-if="infoContent.comentario">
                  <strong>Observación:</strong><br />
                  <span>
                    {{ infoContent.comentario }}
                  </span>
                </p>
                <p v-if="infoContent.lat">
                  <strong>Latitud:</strong>
                  <span>
                    {{ infoContent.lat }}
                  </span>
                </p>
                <p v-if="infoContent.lng">
                  <strong>Longitud:</strong>
                  <span>
                    {{ infoContent.lng }}
                  </span>
                </p>
              </v-col>
            </v-row>
            <!-- CONTENIDO DE LA VENTANA INFORMATIVA -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
              title="Visita previa"
              :disabled="flagPrevVisita"
              @click="fnPrevVisita()"
              small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
              title="Siguiente visita"
              :disabled="flagNextVisita"
              @click="fnNextVisita()"
              small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn> -->

            <!-- <v-btn
              target="_blank"
              :href="'/visita/' + infoContent.id"
              text
              color="primary"
            >
              Ver mas
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </gmap-info-window>
    </GmapMap>
  </div>
</template>
<script>
import moment from 'moment';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import { gmapApi } from 'vue2-google-maps';
export default {
  components: {
    cluster: GmapCluster,
  },
  data() {
    return {
      gmapCenter: { lat: 14.599251, lng: -90.55084 },
      flagCurrentIndex: 0,
      // polyline
      figura_camino_polyline: null,
      poplyLineFlag: false,
      deliveryPath: [],
      deliveryPathColor: '#4DD0E1',
      //
      // place detail
      infoPosition: null,
      infoContent: null,
      infoOpened: false,

      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  name: 'mapa',
  props: {
    prop_markers: {
      type: Array,
    },
  },
  watch: {
    prop_markers() {
      this.infoOpened = false;
      this.deliveryPath = [];
    },
  },
  methods: {
    findWithAttr_next(array, attr, value, index) {
      if (array.length > 1) {
        for (var i = index - 1; i >= 0; i--) {
          if (array[i][attr] == value) {
            return i;
          }
        }
      }

      return -1;
    },
    findWithAttr_prev(array, attr, value, index) {
      let corrida = array.length - index;
      console.log('findWithAttr_prev array length: ', array.length);
      console.log('findWithAttr_prev array corrida: ', corrida);
      console.log('findWithAttr_prev array index: ', index);
      if (array.length >= 1) {
        console.log('findWithAttr_prev');
        for (var i = index + 1; i < array.length; i++) {
          console.log('findWithAttr_prev i', i);
          console.log('findWithAttr_prev array[i]', array[i]);

          console.log('findWithAttr_prev FOR', array[i][attr]);
          if (array[i][attr] == value) {
            console.log('findWithAttr_prev IF', array[i][attr]);
            return i;
          }
        }
      }

      return -1;
    },

    fnGetDate(date) {
      return date ? moment(date).utc().format('DD-MMMM  HH:mm a') : '';
    },
    fnGetColor(label) {
      console.log('buscando color de : ', label);
      if (label.slice(0, 11) == 'Actividades') {
        return 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png';
      }
      let store_visitas = this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      );

      if (store_visitas.length > 0) {
        return store_visitas[0].textColorClass;
      } else {
        let store_otras_visitas = this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.label == label
        )[0].textColorClass;

        return store_otras_visitas;
      }
    },
    fnGetIcon(label) {
      if (label.slice(0, 11) == 'Actividades') {
        return 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png';
      }

      let store_visitas = this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      );

      if (store_visitas.length > 0) {
        return store_visitas[0].marker;
      } else {
        let store_otras_visitas = this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.label == label
        )[0].marker;

        return store_otras_visitas;
      }
    },
    /**
     * polyline & get info
     */

    toggleInfo(marker) {
      this.infoPosition = { lat: marker.lat, lng: marker.lng };
      this.infoContent = marker;
      this.infoOpened = true;
    },
    fnDrawPolyPath(index) {
      this.poplyLineFlag = true;
      this.figura_camino_polyline =
        this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;

      let index_flag = this.findWithAttr_next(
        this.prop_markers_and_pedido,
        'usuario',
        this.prop_markers_and_pedido[index]['usuario'],
        index
      );

      if (index_flag != -1) {
        this.deliveryPath = [];
        this.deliveryPath.push({
          lat: this.prop_markers_and_pedido[index].lat,
          lng: this.prop_markers_and_pedido[index].lng,
        });
        this.deliveryPath.push({
          lat: this.prop_markers_and_pedido[index_flag].lat,
          lng: this.prop_markers_and_pedido[index_flag].lng,
        });
      } else {
        console.log('no hay siguiente entrega');
      }
    },
    // prettier-ignore
    fnNextVisita() {


      //**sí es que existe un registro siguiente, partiendo de la posisición actual */
      // if (
      //   this.prop_markers_and_pedido[this.flagCurrentIndex - 1] != undefined
      // ) {
      //   this.flagCurrentIndex -= 1;
      //   this.gmapCenter.lat =
      //     this.prop_markers_and_pedido[this.flagCurrentIndex].lat;
      //   this.gmapCenter.lng =
      //     this.prop_markers_and_pedido[this.flagCurrentIndex].lng;

      //   this.toggleInfo(this.prop_markers_and_pedido[this.flagCurrentIndex]);
      //   this.fnDrawPolyPath(this.flagCurrentIndex);
      // }
      //** sí es que existe un registro siguiente, pero con el mismo alias de usuario */

      

      let index_flag = this.findWithAttr_next(this.prop_markers_and_pedido, 'usuario',this.prop_markers_and_pedido[this.flagCurrentIndex]['usuario'], this.flagCurrentIndex);

      if (index_flag != -1) {
        
        this.flagCurrentIndex = index_flag;
        this.gmapCenter.lat =
          this.prop_markers_and_pedido[index_flag].lat;
        this.gmapCenter.lng =
          this.prop_markers_and_pedido[index_flag].lng;

        this.toggleInfo(this.prop_markers_and_pedido[index_flag]);
        this.fnDrawPolyPath(index_flag);
      }

    },
    fnPrevVisita() {
      // if (
      //   this.prop_markers_and_pedido[this.flagCurrentIndex + 1] != undefined
      // ) {
      //   this.flagCurrentIndex += 1;
      //   this.gmapCenter.lat =
      //     this.prop_markers_and_pedido[this.flagCurrentIndex].lat;
      //   this.gmapCenter.lng =
      //     this.prop_markers_and_pedido[this.flagCurrentIndex].lng;

      //   this.toggleInfo(this.prop_markers_and_pedido[this.flagCurrentIndex]);
      //   this.fnDrawPolyPath(this.flagCurrentIndex);
      // }
      let index_flag = this.findWithAttr_prev(
        this.prop_markers_and_pedido,
        'usuario',
        this.prop_markers_and_pedido[this.flagCurrentIndex]['usuario'],
        this.flagCurrentIndex
      );

      if (index_flag != -1) {
        this.flagCurrentIndex = index_flag;
        this.gmapCenter.lat = this.prop_markers_and_pedido[index_flag].lat;
        this.gmapCenter.lng = this.prop_markers_and_pedido[index_flag].lng;

        this.toggleInfo(this.prop_markers_and_pedido[index_flag]);
        this.fnDrawPolyPath(index_flag);
      }
    },
    /**
     * polyline & get info
     */
  },
  computed: {
    google() {
      return new gmapApi();
    },
    flagNextVisita() {
      // prettier-ignore
      if (this.prop_markers_and_pedido[this.flagCurrentIndex]['usuario']) {
        let index_flag = this.findWithAttr_next(this.prop_markers_and_pedido, 'usuario',this.prop_markers_and_pedido[this.flagCurrentIndex]['usuario'], this.flagCurrentIndex);

      if (index_flag != -1) {
        return false;
      } else return true;
      }else{
        return true;
      }
    },
    flagPrevVisita() {
      // prettier-ignore
      let index_flag = this.findWithAttr_prev(this.prop_markers_and_pedido, 'usuario',this.prop_markers_and_pedido[this.flagCurrentIndex]['usuario'], this.flagCurrentIndex);
      console.log('flagPrevVisita ', index_flag);
      if (index_flag != -1) {
        return false;
      } else return true;
    },
    centerZoom() {
      return 7;
    },
    centerCoordenates() {
      return { lat: 15.5670864, lng: -90.6112826 };
    },
    prop_markers_and_pedido() {
      let makers = [];

      this.prop_markers.forEach((element) => {
        element.icon = this.fnGetIcon(element.motivo);
        makers.push(element);
      });

      makers = this.prop_markers.filter(function (obj) {
        return obj.lat !== null;
      });

      makers = makers.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.fecha) - new Date(a.fecha);
      });

      // makers.forEach((element) => {
      /// //   console.log(element.motivo, element.fecha);
      // });
      return makers;
    },
  },
  mounted() {},
};
</script>
