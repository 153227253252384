<template>
  <div>
    <v-btn
      @click="fnExportChartJsToPng"
      class="float-right mb-1"
      small
      elevation="2"
      color=" primary"
      text
    >
      <v-icon sm color="">mdi-download</v-icon> (PNG)</v-btn
    >
    <canvas id="general-grafica-regiones"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'generalGraficaSectores',
  mounted() {
    const ctx = document
      .getElementById('general-grafica-regiones')
      .getContext('2d');
    this.myCHart = new Chart(ctx, this.motivosDeVisitaData);
    /**
     *
     */
    // main()
    // this.fnUpdateDataSet_labels(this.prop_totales);
  },
  props: ['prop_data'],
  data() {
    return {
      myCHart: null,
      myChartBase64: '',
      motivosDeVisitaData: {
        type: 'bar',
        data: {
          labels: ['#check-ins por region'],
          datasets: [
            {
              label: 'Regiones',
              data: [],
              backgroundColor: 'rgba(252, 184, 179, 0.7)',
              borderColor: '#FF004F',
            },
          ],
        },
      },
    };
  },

  watch: {
    prop_data(newValue) {
      if (newValue) {
        this.motivosDeVisitaData.data.datasets = newValue;
        this.myCHart.update();
      }
    },
  },
  methods: {
    fnExportChartJsToPng() {
      this.myChartBase64 = this.myCHart.toBase64Image();

      var a = document.createElement('a'); //Create <a>
      a.href = this.myChartBase64; //Image Base64 Goes here
      a.download = 'Image.png'; //File name Here
      a.click(); //Downloaded file
    },
    fnGetBackground(label) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      )[0].background;
    },
    fnGetBorder(label) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.label == label
      )[0].border;
    },
    fnUpdateDataSet_labels(newValue) {
      if (newValue) {
        this.motivosDeVisitaData.data.labels = [];
        for (let index = 1; index <= newValue.length; index++) {
          this.motivosDeVisitaData.data.labels.push('Semana ' + index);
        }
        this.myCHart.update();
      }
    },
    fnUpdateDataSet_data(newValue) {
      if (newValue.length >= 1) {
        /**
         * recorrido de semanas
         * week count = this.prop_totales.length
         */
        for (let index = 0; index < newValue.length; index++) {
          /**
           *
           * recorrido de motivos por semana
           */
          newValue[index].forEach((motivo) => {
            let dataset_index =
              this.motivosDeVisitaData.data.datasets.findIndex(
                (el) => el.label === motivo['motivo_de_visita']
              );
            if (dataset_index != -1) {
              this.motivosDeVisitaData.data.datasets[dataset_index].data[
                index
              ] = motivo['totalVisitas'];
            }
          });
        }
        this.myCHart.update();
      }
    },
  },
};
</script>
<style scoped>
#general-grafica-regiones {
  max-height: 70vh;
}
</style>
